import React, { Fragment } from 'react';
import { Flex, Icon, Button, Text } from '@chakra-ui/react';
import { MdDone } from 'react-icons/md';

const Done = ({ onClose, amount }) => {
  const message = `The seller will have 24 hours to agree to your offer.`;
  return (
    <Fragment>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="50px">
        <Flex bg="#deeefa" p="8px 7px 7px 8px" w="90px" h="90px" borderRadius="50%" justifyContent="center">
          <Icon as={MdDone} fontSize="55px" color="#2085FF" alignSelf="center" />
        </Flex>
        <Text fontSize="16px" fontWeight="bold" mt="20px">
          Offer sent for ${amount}
        </Text>
        <Text mt="10px" mb="40px" fontSize="12px" px="50px" textAlign="center" fontWeight="500">
          {message}
        </Text>
      </Flex>
      <Flex py="15px" px="5px" justifyContent="center">
        <Button colorScheme="messenger" bg="#012aff" w="100%" h="42px" onClick={onClose}>
          OK
        </Button>
      </Flex>
    </Fragment>
  );
};
export default Done;
