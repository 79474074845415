import React, { useEffect } from 'react';
import { Flex, Text, Button, Icon, Image, Avatar, Box, InputGroup, Input, InputRightElement } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { FormErrorMessage, FormLabel, FormControl, useToast } from '@chakra-ui/react';
import { MdClose, MdDone } from 'react-icons/md';
import Select from 'react-select';

const colourStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      paddingLeft: '5px',
      backgroundColor: '#fff',
      borderColor: '#E2E8F0',
      borderRadius: '5px',
      minHeight: '42px',
      fontSize: '14px',
      fontWeight: '500',
      borderWidth: '1px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: isFocused ? '#000' : null,
      },
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#3182ce' : '#fff',
      color: isSelected ? '#fff' : '',
      fontSize: '13px',
      padding: '5px 15px',
      ':hover': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : '#DEEEFA'),
        color: '',
      },
    };
  },
  singleValue: (styles) => ({ ...styles, color: '' }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
};

const ModalAddCollection = ({ visible, setVisible, nftData = {} }) => {
  const { name } = nftData;

  const [options, setOptions] = React.useState([]);
  const [collection, setCollection] = React.useState('');
  const [value, setValue] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const toast = useToast();

  useEffect(() => {
    setSuccess(false);
  }, []);

  const onSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
    }, 1000);
  };

  const onAddCollection = () => {
    if (value) {
      setCargando(true);
      setTimeout(() => {
        toast({
          title: 'Collection created',
          status: 'success',
          position: 'top',
          variant: 'left-accent',
          isClosable: true,
        });
        setOptions([...options, value]);
        setValue('');
        setCollection('');
        setCargando(false);
      }, 500);
    }
  };

  return (
    <Modal isOpen={visible} onClose={() => setVisible(false)} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mt="236px" w="480px">
        {success ? (
          <ModalBody p={0}>
            <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="70px">
              <Flex bg="#deeefa" p="8px 7px 7px 8px" w="90px" h="90px" borderRadius="50%" justifyContent="center">
                <Icon as={MdDone} fontSize="55px" color="#2085FF" alignSelf="center" />
              </Flex>
              <Text mt="35px" mb="40px" fontSize="14px" textAlign="center" fontWeight="500">
                Added to your collection
              </Text>
            </Flex>
            <Flex py="15px" px="35px" borderTopWidth={2} justifyContent="center">
              <Button variant="black" w="100%" onClick={() => setVisible(false)}>
                Continue
              </Button>
            </Flex>
          </ModalBody>
        ) : (
          <ModalBody p={0}>
            <Flex pos="relative" py="15px" borderBottomWidth={2} alignItems="center" justifyContent="center">
              <Icon
                as={MdClose}
                pos="absolute"
                onClick={() => setVisible(false)}
                left="20px"
                cursor="pointer"
                fontSize="25px"
              />
              <Text fontSize="16px" fontWeight="500">
                Add to collection
              </Text>
            </Flex>
            <Flex py="15px" px="35px" borderBottomWidth={2} justifyContent="center">
              <Flex borderWidth={1} p="8px 10px" w="100%" borderRadius="4px">
                {/* <Image src={data} w="52px" h="52px" alt="" mr="10px" borderRadius={4} objectFit="cover" /> */}
                <Flex flexDirection="column">
                  <Text fontSize="14px" fontWeight="bold">
                    {name}
                  </Text>
                  <Flex alignItems="center">
                    <Avatar size="sm" w="24px" h="24px" name="a" mr="6px" bg="#BC3153" color="#fff" />
                    <Text fontSize="12px">a</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex py="15px" w="100%" flexDirection="column">
              <Box w="100%" px="35px" borderBottomWidth={2} pb="5px">
                <FormControl mb="23px">
                  <FormLabel mb="3px" fontSize="12px" fontWeight="600">
                    Add to existing collection
                  </FormLabel>
                  <Select
                    isClearable={true}
                    placeholder="Select existing collection"
                    styles={colourStyles}
                    value={collection}
                    onChange={(val) => setCollection(val)}
                    options={options.map((i) => ({ label: i, value: i }))}
                  />

                  {false && (
                    <FormErrorMessage fontSize="13px" mt={1}>
                      {submittedError || touchedError ? hasError : false}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl mb="23px">
                  <FormLabel mb="3px" fontSize="12px" fontWeight="600">
                    New collection
                  </FormLabel>

                  <InputGroup size="md">
                    <Input
                      value={value}
                      fontSize="14px"
                      type="text"
                      onChange={(e) => setValue(e.target.value)}
                      _placeholder={{ color: '#7f7f7f', fontSize: '14px' }}
                      _invalid={{ borderColor: '#e53e3e' }}
                      _focus={{ borderColor: '#000' }}
                      _disabled={{ color: 'gray.500' }}
                      color="#141416"
                      fontWeight="500"
                    />
                    <InputRightElement width="90px" zIndex="0">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={onAddCollection}
                        isDisabled={!value ? true : false}
                        variant="black"
                        _disabled={{ background: '#7f7f7f', color: 'white' }}
                        _hover={{ background: '#7f7f7f' }}
                        fontWeight="400"
                        isLoading={cargando}
                        zIndex="0"
                      >
                        create
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  {false && (
                    <FormErrorMessage fontSize="13px" mt={1}>
                      {submittedError || touchedError ? hasError : false}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              <Box w="100%" px="35px">
                <Button
                  width="100%"
                  isLoading={loading}
                  type="submit"
                  height="40px"
                  mt="12px"
                  isDisabled={!collection ? true : false}
                  variant="black"
                  _disabled={{ background: '#7f7f7f', color: 'white' }}
                  fontSize="14px"
                  fontWeight="500"
                  _hover={{ bg: '#7f7f7f' }}
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalAddCollection;
