import React from 'react';
import { Text, Tabs, TabList, Tab, useColorMode } from '@chakra-ui/react';

const TabCollections = ({ current, onTab }) => {
  // console.log('current', current);
  const { colorMode } = useColorMode();
  const tabStyle = {
    fontSize: { base: '14px', md: '24px' },
    _hover: { outline: 'none' },
    _focus: { borderColor: '#3182ce' },
    // _selected: { borderBottom: '2px', borderColor: '#3182CE' },
  };

  const tabSelected = {
    color: colorMode === 'dark' ? '#fff' : '#141416',
    borderColor: colorMode === 'dark' ? '#b1b1b1' : '#141416',
  };

  // console.log('current', current);

  return (
    <Tabs id="tabs" mx={{ base: '10x', md: 0 }} defaultIndex={Number(current) - 1}>
      <TabList color="#7f7f7f" borderColor={colorMode === 'dark' ? '#333' : null}>
        <Tab
          {...tabStyle}
          _selected={tabSelected}
          borderColor={current === 'curated' || !current ? '#141416' : null}
          mr={{ base: '3px', md: '50px' }}
          onClick={() => onTab('curated')}
        >
          <Text mb="10px" fontWeight="bold">
            Curated
          </Text>
        </Tab>

        <Tab
          {...tabStyle}
          _selected={tabSelected}
          borderColor={current === 'art' ? '#141416' : null}
          mr={{ base: '3px', md: '50px' }}
          onClick={() => onTab('art')}
        >
          <Text mb="10px" fontWeight="bold">
            Art
          </Text>
        </Tab>

        <Tab
          {...tabStyle}
          _selected={tabSelected}
          borderColor={current === 'collections' ? '#141416' : null}
          mr={{ base: '3px', md: '50px' }}
          onClick={() => onTab('collections')}
        >
          <Text mb="10px" fontWeight="bold">
            Collections
          </Text>
        </Tab>
      </TabList>
    </Tabs>
  );
};
export default TabCollections;
