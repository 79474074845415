import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { deviceType } from 'react-device-detect';
import { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { FiChevronDown, FiChevronUp, FiFilter } from 'react-icons/fi';
import { SimpleGrid, Box, Flex, Text, Select, Icon, Input, useColorMode, Button, Checkbox } from '@chakra-ui/react';
import { Container } from 'components/Container';
import ModalFav from 'components/ModalAddCollection';
import ModalPlaceABid from 'components/Modal/PlaceABid';
import ModalPurchase from 'components/Modal/Purchase';
import ModalEditions from 'components/Modal/Editions';
import ModalOffer from './Modal/MakeOffer';
import notification from 'components/Notifications';
import Filters from 'components/Filters';
// import FiltersMobile from 'components/Filters/mobile';
import CardCollection from './CardCollection';
import ModalLoading from './Modal/Loading';
import { getAllTokenData } from 'utils/stacks';
import { API } from 'config/api';
import { AuthContext } from 'contexts/auth/auth.context';
import TabCollections from './TabCollections';
import { useHttpBid } from 'lib/useHttp';
import { useAuthDispatch } from 'contexts/0Auth';
import { bgColor, borderColor } from 'config/data';
import { isEmpty } from '@antv/util';
import { RiCloseFill } from 'react-icons/ri';
import { useRouterParams } from 'hooks/usrRouterParams';
import { getArrayFilter, unslugify } from 'utils/utility';
import { useCurrency } from 'contexts/currency/currency.provider';

function getNameTag(tag, tags = []) {
  const find = tags?.find((el) => el.id === Number(tag));
  return find?.name || '';
}

const ButtonFilter = ({ i, onDeleteQuery, type, tags, uppercase }) => {
  const { colorMode } = useColorMode();
  return (
    <Button
      key={i}
      mr="15px"
      mb="15px"
      variant="outline"
      bg={colorMode === 'dark' ? '#373737' : '#f7f7f7'}
      size="sm"
      borderRadius={8}
      px="14px"
      onClick={onDeleteQuery}
    >
      {type === 'tags' ? getNameTag(i, tags) : uppercase ? i.toUpperCase() : decodeURI(unslugify(i))}

      <Icon as={RiCloseFill} mt="2px" ml="5px" fontSize="18px" />
    </Button>
  );
};

export const Collections = ({ data, isUser, user, favorites, query, isCollection, slug, showFilters = false }) => {
  const { mutate } = useSWRConfig();
  const router = useRouter();
  const [id, setId] = useState(-1);
  const [type, setType] = useState('');
  const [visible, setVisible] = useState(false);
  const [nftData, setNftData] = useState({});
  const [usdPurchaseData, setUsdPurchaseData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cargando, setCargando] = useState(false);

  const [filter, setFilter] = useState(['status']);
  const [open, setOpen] = useState(true);
  const [filterType, setFilterType] = useState('status');
  const [select, setSelect] = useState('');
  const [status, setStatus] = useState('');
  const [unverified, setUnVerified] = useState('1');
  const [explicit, setExplicit] = useState('0');
  const [device, setDevice] = useState('');
  const [current, setCurrent] = useState(query?.type || 'art');
  const { colorMode } = useColorMode();
  const { authDispatch } = useContext(AuthContext);
  const { goToOauth } = useAuthDispatch();
  const pathname = router.asPath;
  const { removeParam, clearParams } = useRouterParams();
  const { showFilter, changeFilter } = useCurrency();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setDevice(deviceType);
      if (query) {
        setSelect(query.order);
        setStatus(query.sales_method);
        setCurrent(query.type);
        setUnVerified(query.include_unverified);
        setExplicit(query.include_explicit);
      }
    }
    return () => (isSubscribed = false);
  }, [query]);

  const access_token = user?.access_token;

  const { data: wallets } = useHttpBid(
    `${process.env.NEXT_PUBLIC_API_URL}/user/${user?.username}/wallets`,
    user?.isLoggedIn
  );

  const { data: tags } = useHttpBid(`${process.env.NEXT_PUBLIC_API_URL}/metadata/tags`, true);
  const { data: surprise } = useHttpBid(`/api/surprise`, true);

  const onSurpriseMe = () => {
    router.push(surprise.data);
  };

  const onOpenModal = async (values, type) => {
    // console.log('values', values);
    const { contract_token_id: token_id, id: nft_id } = values;
    if (type === 'purchase') {
      if (user?.isLoggedIn) {
        setIsLoading(true);
        if (values.for_sale === null && token_id) {
          // console.log('contract');
          getAllTokenData(token_id).then(
            (tokenData) => {
              setNftData({
                ...values,
                forSale: tokenData?.metaData?.['for-sale']?.value || false,
                owner: tokenData.owner,
                priceSTX: tokenData.metaData['price'].value,
                error: '',
              });
              setIsLoading(false);
              setType(type);
              setVisible(true);
            },
            (err) => {
              setNftData({ ...values });
              setIsLoading(false);
              setType(type);
              setVisible(true);
            }
          );
        } else {
          setNftData({ ...values });
          setIsLoading(false);
          setType(type);
          setVisible(true);
        }
      } else {
        // authDispatch({ type: 'SHOW_MODAL', payload: nft_id });
        await axios.post('/api/current', { pathname });
        goToOauth();
      }
    } else if (type === 'favorite') {
      if (user?.isLoggedIn) {
        setId(nft_id);
        setType(type);
        setNftData({ ...values, forSale: false, error: '' });
        const config = { headers: { Authorization: `Bearer ${access_token}` } };
        API.post(`${process.env.NEXT_PUBLIC_API_URL}/me/favorites`, { id: nft_id }, config)
          .then((res) => {
            notification('success', `❤️ ${res.data.message}`);
            mutate([`${process.env.NEXT_PUBLIC_API_URL}/me/favorites`, access_token]);
          })
          .catch((err) => notification('error', `${err.response.data.message}`));
      } else {
        // authDispatch({ type: 'SHOW_MODAL' });
        await axios.post('/api/current', { pathname });
        goToOauth();
      }
    } else if (type === 'bid') {
      if (user?.isLoggedIn) {
        if (token_id) {
          getAllTokenData(token_id).then(
            (tokenData) => {
              setNftData({
                ...values,
                forSale: tokenData?.metaData?.['for-sale']?.value || false,
                owner: tokenData.owner,
                priceSTX: tokenData.metaData['price'].value,
                error: '',
              });
              setIsLoading(false);
              setType(type);
              setVisible(true);
              setId(nft_id);
            },
            (err) => {
              setNftData({ ...values });
              setIsLoading(false);
              setType(type);
              setVisible(true);
            }
          );
        } else {
          setId(nft_id);
          setType(type);
          setVisible(true);
          setNftData({ ...values, forSale: false, error: '' });
        }
      } else {
        // authDispatch({ type: 'SHOW_MODAL' });
        await axios.post('/api/current', { pathname });
        goToOauth();
      }
    } else if (type === 'editions') {
      setNftData({ ...values });
      setIsLoading(false);
      setType(type);
      setVisible(true);
    } else {
      setId(nft_id);
      setType(type);
      setVisible(true);
      setNftData({ ...values, forSale: false, error: '' });
    }
  };

  const onDeleteFavorite = (id) => {
    API.delete(`/me/favorites`, { headers: { Authorization: access_token }, data: { id } })
      .then((res) => {
        notification('success', `💔 ${res.data.message}`);
        mutate([`${process.env.NEXT_PUBLIC_API_URL}/me/favorites`, access_token]);
      })
      .catch((err) => notification('error', err.response.data.message));
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  const onButton = () => {
    setVisible(false);
    setId(-1);
    setType('');
  };

  const onReport = async (data) => {
    data.email = user?.email || 'support@heylayer.com';
    if (typeof window !== 'undefined') {
      setCargando(true);
      setId(data.id);
      const hostname = window.location.hostname;
      const url = `${hostname}/nft/${data.id}`;
      axios
        .post('/api/report', { url, ...data })
        .then((res) => {
          notification('success', res.data.message);
          setCargando(false);
        })
        .catch(() => setCargando(false));
    }
  };

  const onHide = async (values) => {
    console.log('values', values);
  };

  const onSelect = (event) => {
    const { value } = event.target;
    router.query.order = value;
    router.query.page = 1;
    router.replace(router);
  };

  const onSelectStatus = (value) => {
    // console.log(value);
    router.query.sales_method = value;
    router.query.page = 1;
    router.replace(router);
  };

  const onSelectVerifed = (value) => {
    // console.log('value', value);
    router.query.include_unverified = value;
    router.replace(router);
  };

  const onSelectExplicit = (value) => {
    // console.log('value', value);
    router.query.include_explicit = value;
    router.replace(router);
  };

  const onTab = (tab) => {
    router.query.type = tab;
    // router.query.page = 1;
    router.replace(router);
  };

  const debouncedSave = useMemo(
    () =>
      debounce((val) => {
        if (val) {
          router.query.name = val;
          router.replace(router);
        } else {
          delete router.query.name;
          router.replace(router);
        }
      }, 500),
    [router]
  );

  const onDeleteQuery = (type, value) => {
    setLoading(true);
    setTimeout(() => {
      removeParam(type, decodeURI(value));
      setLoading(false);
    }, 100);
  };

  const onDeleteQueryPrice = () => {
    const currentPath = router.pathname;
    const currentQuery = router.query;
    delete currentQuery.currency;
    delete currentQuery.min;
    delete currentQuery.max;
    router.push({
      pathname: currentPath,
      query: currentQuery,
    });
  };

  const filterEdition = data?.filter((el) => el.edition !== null);
  const totalEdition = filterEdition.length;
  const props = { visible, setVisible, setNftData, onSubmit, onButton, nftData, usdPurchaseData, access_token };

  const arrStatus = getArrayFilter(query?.status);
  const arrListed = getArrayFilter(query?.listed);
  const arrFeatures = getArrayFilter(query?.features);
  const arrCollections = getArrayFilter(query?.collections);
  const arrTags = getArrayFilter(query?.tags);
  const arrExplicit = getArrayFilter(query?.include_explicit);
  const arrVerified = getArrayFilter(query?.creator_verified);
  const arrSurprice = getArrayFilter(query?.surprise);
  const arrCurrency = getArrayFilter(query?.currencies);
  const arrSince = getArrayFilter(query?.since);

  return (
    <Box
      as="section"
      id="collections"
      p={{ base: isCollection ? '20px 0 0' : '5px 0', md: isCollection ? '40px 40px 20px' : '20px 40px' }}
      px={['15px', '10px', 0]}
    >
      {isLoading && <ModalLoading />}
      {type === 'favorite' && <ModalFav {...props} />}
      {type === 'bid' && <ModalPlaceABid {...props} />}
      {type === 'purchase' && <ModalPurchase {...props} wallets={wallets?.data || []} />}
      {type === 'editions' && <ModalEditions {...props} onOpenModal={onOpenModal} />}
      {type === 'offer' && <ModalOffer {...props} onOpenModal={onOpenModal} />}
      <Container>
        {showFilters && (
          <Flex mb="0px" id="filters" justifyContent="space-between">
            <Flex cursor="pointer" alignItems="center"></Flex>
            <Flex id="der" alignItems="center">
              <Box pos="relative" bg={bgColor[colorMode]}>
                <Select
                  borderRadius="10px"
                  size="sm"
                  h="35px"
                  value={select}
                  onChange={onSelect}
                  borderColor={borderColor[colorMode]}
                >
                  <option value="latest">Latest</option>
                  <option value="oldest">Oldest</option>
                  <option value="lowest">Lowest price</option>
                  <option value="highest">Highest price</option>
                </Select>
              </Box>
            </Flex>
          </Flex>
        )}

        {showFilters && <TabCollections current={current} onTab={onTab} />}
        <Flex id="wrap">
          <Flex id="collections_content">
            {router.pathname === '/' && (
              <Filters
                tags={tags?.data}
                device={device}
                loading={loading}
                query={query}
                showFilter={showFilter}
                setOpenFilter={changeFilter}
                open={open}
                setOpen={setOpen}
                filterType={filterType}
                setFilterType={setFilterType}
                filter={filter}
                setFilter={setFilter}
                onSurpriseMe={onSurpriseMe}
              />
            )}

            <Box id="content" pt={{ base: '25px', md: '25px' }}>
              {(!isEmpty(arrListed) ||
                !isEmpty(arrStatus) ||
                !isEmpty(arrCollections) ||
                !isEmpty(arrTags) ||
                !isEmpty(arrFeatures) ||
                !isEmpty(arrExplicit) ||
                !isEmpty(arrVerified) ||
                !isEmpty(arrSurprice) ||
                !isEmpty(arrCurrency) ||
                !isEmpty(arrSince) ||
                !isEmpty(query?.min)) && (
                <Flex
                  w={{ base: '100%', md: '988px' }}
                  mb="8px"
                  flexWrap="wrap"
                  display={device === 'mobile' && showFilter ? 'none' : null}
                >
                  {arrListed.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('listed', i)} />
                  ))}
                  {arrFeatures.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('features', i)} />
                  ))}
                  {arrStatus.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('status', i)} />
                  ))}
                  {arrCollections.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('collections', i)} />
                  ))}
                  {arrTags.map((i) => (
                    <ButtonFilter
                      key={i}
                      i={i}
                      type="tags"
                      tags={tags?.data}
                      onDeleteQuery={() => onDeleteQuery('tags', i)}
                    />
                  ))}
                  {arrExplicit.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('include_explicit', i)} />
                  ))}
                  {arrVerified.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('creator_verified', i)} />
                  ))}
                  {arrCurrency.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('currencies', i)} uppercase={true} />
                  ))}
                  {arrSince.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('since', i)} />
                  ))}
                  {(query.min || query.max || query.currency) && (
                    <Button
                      mr="15px"
                      mb="15px"
                      variant="outline"
                      bg={colorMode === 'dark' ? '#373737' : '#f7f7f7'}
                      size="sm"
                      borderRadius={8}
                      px="14px"
                      onClick={() => onDeleteQueryPrice()}
                    >
                      From {query.min} to {query.max} USD
                      <Icon as={RiCloseFill} mt="2px" ml="5px" fontSize="18px" />
                    </Button>
                  )}
                  {arrSurprice.map((i) => (
                    <ButtonFilter key={i} i={i} onDeleteQuery={() => onDeleteQuery('surprise', i)} />
                  ))}
                  <Button
                    variant="outline"
                    borderColor={colorMode === 'dark' ? '#333' : '#000'}
                    size="sm"
                    onClick={() => {
                      setFilter([]);
                      clearParams();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Flex>
              )}
              <SimpleGrid
                id="grid"
                columns={{
                  base: 1,
                  md: showFilter && !isCollection ? 1 : 2,
                  lg: showFilter && !isCollection ? 2 : 3,
                  xl: showFilter && !isCollection ? 3 : 4,
                }}
                spacing="40px"
                display={device === 'mobile' && showFilter && !isCollection ? 'none' : null}
              >
                {data.map((nft, idx) => {
                  return (
                    <CardCollection
                      id={id}
                      key={idx}
                      number={idx + 1}
                      total={totalEdition}
                      nftData={nft}
                      // filter={filter}
                      isUser={isUser}
                      favorites={favorites}
                      isLoading={cargando}
                      onOpenModal={onOpenModal}
                      onDeleteFavorite={onDeleteFavorite}
                      onReport={onReport}
                      onHide={onHide}
                      isCollection={isCollection}
                      slug={slug}
                      type={current}
                      setId={setId}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
