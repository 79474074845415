import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, useColorMode, Icon } from '@chakra-ui/react';
import { Flex, Heading, Image, Text, Divider, Alert, UnorderedList, ListItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSWRConfig } from 'swr';
import notification from 'components/Notifications';
import Step1 from './step1';
import useUser from 'lib/useUser';
import Done from './done';
// import { useRouter } from 'next/router';
// import axios from 'axios';
import { API } from 'config/api';
import { bgColor, borderColor } from 'config/data';
import { RiCloseCircleLine } from 'react-icons/ri';

const ModalOffer = ({ visible, setVisible, setNftData, nftData = {}, access_token, forSale }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState(null);
  const { colorMode } = useColorMode();
  const { mutate } = useSWRConfig();

  const { user } = useUser();

  useEffect(() => {
    setStep(1);
    setErrors({});
  }, []);

  const onConfirm = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(2);
      setLoading(false);
    }, 1000);
  };

  const onSubmit = (value, currency) => {
    setAmount(value);
    setLoading(true);
    const data = {
      non_fungible_token_id: nftData.id,
      amount: Number(value),
      currency: currency.toLowerCase(),
    };
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    API.post('/purchaseOffer', data, config)
      .then((res) => {
        setLoading(false);
        setStep(2);
        setErrors({});
        mutate([`${process.env.NEXT_PUBLIC_API_URL}/purchaseOffer/outgoing`, access_token]);
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          setErrors(e.response?.data?.errors || {});
        } else {
          notification('error', `❌${e.response.data.message}`);
        }
        setLoading(false);
      });

    // const hostname = window.location.hostname;
    // const url = `${hostname}/nft/${nftData.id}`;
    // axios.post('/api/nft/make_offer', { nft: nftData, offer: value, currency, url }).then((res) => {
    //   console.log(res.data);
    //   setLoading(false);
    //   setStep(2);
    // });
  };

  const onClose = () => {
    setStep(1);
    setVisible(false);
    setNftData({});
  };

  const onBack = (step) => {
    setStep(step);
  };

  const hasErrors = errors ? Object.entries(errors) : {};

  const propsModal = { loading, setVisible, onClose, nftData, onBack, user, access_token, forSale, amount };
  return (
    <Modal isOpen={visible} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mt={{ base: '30px', md: '236px' }} w={{ base: '90%', md: '480px' }}  bg={bgColor[colorMode]} color={colorMode === 'dark' ? 'gray.300' : null}>
        <ModalBody p="20px">
          {step === 1 && (
            <Fragment>
              <Flex justifyContent="flex-end" alignItems="center">
                <Text fontSize={['16px', '16px', '12px']} mr="10px" fontWeight="bold">
                  CLOSE
                </Text>
                <Flex cursor="pointer" onClick={onClose}>
                  <Icon as={RiCloseCircleLine} fontSize={['31px', '31px', '23px']} />
                </Flex>
              </Flex>
              <Heading fontSize={['36px', '36px', '24px']} mb="10px" fontWeight="bold">
                MAKE AN OFFER
              </Heading>
              <Divider borderWidth={1} borderColor={borderColor[colorMode]} mb="5px" />
            </Fragment>
          )}
          <Fragment>
            {!isEmpty(errors) && (
              <Alert
                status="error"
                mb="20px"
                mt="10px"
                borderRadius={5}
                fontSize="13px"
                flexDirection="column"
                alignItems="start"
              >
                {hasErrors.map((i, idx) => {
                  const value = i[1];
                  return (
                    <Flex flexDirection="column" key={idx}>
                      <UnorderedList>
                        {value.map((i, index) => (
                          <ListItem key={index}>{i}</ListItem>
                        ))}
                      </UnorderedList>
                    </Flex>
                  );
                })}
              </Alert>
            )}
            {step === 1 && <Step1 {...propsModal} onSubmit={onSubmit} />}
            {step === 2 && <Done {...propsModal} onConfirm={onConfirm} />}
          </Fragment>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalOffer;
