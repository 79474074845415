import { Box, Button, Flex, Icon, Image, Input, SimpleGrid, Text, useColorMode } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';
import { debounce, isEmpty } from 'lodash';
import Loading from 'components/Loading';
import { borderColor } from 'config/data';
import { useHttpBid } from 'lib/useHttp';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useMemo, useState } from 'react';
import Sticky from 'react-stickynode';
import { Scrollbars } from 'react-custom-scrollbars';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { RiCloseFill } from 'react-icons/ri';
import { FilterIcon } from 'theme/icons';
import PriceForm from './PriceForm';
import { API } from 'config/api';
import { useRouterParams } from 'hooks/usrRouterParams';
import { getArrayFilter } from 'utils/utility';
import axios from 'axios';

const FilterContent = ({ children, id }) => {
  const { colorMode } = useColorMode();
  return (
    <Flex id={id} mt="15px" pt="15px" flexDirection="column" borderTopWidth={1} borderColor={borderColor[colorMode]}>
      {children}
    </Flex>
  );
};

const FilterMenu = ({ filter = [], filterName, onOpenFilter }) => {
  const exist = filter.find((el) => el === filterName);
  return (
    <Flex
      id="name"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={() => onOpenFilter(filterName)}
    >
      <Text fontSize="16px" fontWeight="600" textTransform="capitalize">
        {filterName}
      </Text>
      <Icon as={exist && open ? FiChevronDown : FiChevronUp} fontSize="22px" />
    </Flex>
  );
};

const Filters = ({
  device,
  loading,
  showFilter,
  setOpenFilter,
  filter = [],
  setFilter,
  open,
  setStatus,
  query,
  tags,
  onSurpriseMe,
}) => {
  // console.log('showFilter', showFilter);
  const { colorMode } = useColorMode();
  const router = useRouter();
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { toggleParam } = useRouterParams();

  useEffect(() => {
    setIsLoading(true);
    API.get(`/nonFungibleTokenCollections/featured`)
      .then((res) => {
        setCollections(res.data.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const onClearFilters = () => {
    setStatus('');
    router.replace('/marketplace');
  };

  const onOpenFilter = (filtro) => {
    const find = filter.find((el) => el === filtro);
    if (find) {
      setFilter(filter.filter((el) => el !== filtro));
    } else {
      setFilter([...filter, filtro]);
    }
  };

  const getOpenFilter = (name) => {
    const exist = filter?.find((el) => el === name);
    return exist;
  };

  const onAddParam = (type, status) => {
    toggleParam(type, status);
  };

  const onSubmitPrice = (values) => {
    const currentPath = router.pathname;
    const currentQuery = router.query;
    currentQuery.currency = values.currency;
    currentQuery.min = values.min || 1;
    currentQuery.max = values.max || 100;
    router.push({
      pathname: currentPath,
      query: currentQuery,
    });
  };

  const debouncedSave = useMemo(
    () =>
      debounce((val) => {
        if (val) {
          setIsLoading(true);
          API.get(`collections/list?query=${val}`)
            .then((res) => {
              setCollections(res.data.data);
              setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        } else {
          API.get(`/nonFungibleTokenCollections/featured`)
            .then((res) => {
              setCollections(res.data.data);
              setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        }
      }, 500),
    []
  );

  const isActive = (val, type) => {
    const arr = isEmpty(type) ? [] : Array.isArray(type) ? type : type.split(',');
    const active = arr?.find((el) => decodeURI(el) === val);
    return active;
  };
  const queryCollections = getArrayFilter(query?.collections);
  const queryTags = getArrayFilter(query?.tags);
  return (
    <Fragment>
      {showFilter ? (
        <Box
          id="filters"
          w={device === 'mobile' ? '100%' : '300px'}
          pt={{ base: '25px', md: '25px' }}
          pl="10px"
          pr="15px"
          mr="25px"
          borderRightWidth={1}
          borderColor={borderColor[colorMode]}
          minH="65vh"
        >
          <Sticky enabled={device === 'mobile' ? false : true} top={20} bottom={60}>
            <Scrollbars style={{ width: device === 'mobile' ? 350 : 280, height: '95vh' }}>
              <Flex flexDirection="column" mr="20px" ml="3px">
                <Flex mb="10px" justifyContent="space-between" id="header" alignItems="center">
                  <Flex id="filter">
                    <FilterIcon color={colorMode === 'dark' ? '#fff' : '#141414'} />
                    <Text ml="15px" fontWeight="semibold">
                      Filter
                    </Text>
                  </Flex>
                  <Icon as={RiCloseFill} onClick={() => setOpenFilter(false)} cursor="pointer" fontSize="22px" />
                </Flex>
                <Flex
                  id="status"
                  py="15px"
                  borderBottomWidth={1}
                  borderColor={borderColor[colorMode]}
                  flexDirection="column"
                >
                  <FilterMenu filter={filter} filterName="status" onOpenFilter={() => onOpenFilter('status')} />
                  {getOpenFilter('status') && open && (
                    <FilterContent id="content">
                      <SimpleGrid columns={[2, 2]} spacing="14px" mb="14px">
                        <Button
                          bg={
                            isActive('buy_it_now', query?.listed)
                              ? colorMode === 'dark'
                                ? '#373737'
                                : '#F3F3F3'
                              : null
                          }
                          borderColor={isActive('buy_it_now', query?.listed) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('listed', 'buy_it_now')}
                        >
                          Buy Now
                        </Button>
                        <Button
                          bg={
                            isActive('auction', query?.listed) ? (colorMode === 'dark' ? '#373737' : '#F3F3F3') : null
                          }
                          borderColor={isActive('auction', query?.listed) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('listed', 'auction')}
                        >
                          Live Auction
                        </Button>
                        <Button
                          bg={isActive('New', query?.since) ? (colorMode === 'dark' ? '#373737' : '#F3F3F3') : null}
                          borderColor={isActive('New', query?.since) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('since', 'New')}
                        >
                          New
                        </Button>
                        <Button
                          bg={
                            isActive('usd', query?.currencies) ? (colorMode === 'dark' ? '#373737' : '#F3F3F3') : null
                          }
                          borderColor={isActive('usd', query?.currencies) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('currencies', 'usd')}
                        >
                          USD Only
                        </Button>
                        <Button
                          bg={
                            isActive('unlockables', query?.features)
                              ? colorMode === 'dark'
                                ? '#373737'
                                : '#F3F3F3'
                              : null
                          }
                          borderColor={isActive('unlockables', query?.features) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('features', 'unlockables')}
                        >
                          Unlockables
                        </Button>
                        <Button
                          bg={
                            isActive('charity', query?.features) ? (colorMode === 'dark' ? '#373737' : '#F3F3F3') : null
                          }
                          borderColor={isActive('charity', query?.features) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('features', 'charity')}
                        >
                          Charity
                        </Button>
                        <Button
                          bg={
                            isActive('explicit', query?.include_explicit)
                              ? colorMode === 'dark'
                                ? '#373737'
                                : '#F3F3F3'
                              : null
                          }
                          borderColor={isActive('explicit', query?.include_explicit) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('include_explicit', 'explicit')}
                        >
                          Explicit
                        </Button>
                        <Button
                          bg={
                            isActive('unverified', query?.creator_verified)
                              ? colorMode === 'dark'
                                ? '#373737'
                                : '#F3F3F3'
                              : null
                          }
                          borderColor={isActive('unverified', query?.creator_verified) ? '#828282' : null}
                          variant="outline"
                          borderRadius={8}
                          fontSize="14px"
                          h="35px"
                          onClick={() => onAddParam('creator_verified', 'unverified')}
                        >
                          Unverified
                        </Button>
                      </SimpleGrid>
                      <Button variant="black" h="35px" onClick={() => onSurpriseMe()}>
                        Surprise Me
                      </Button>
                    </FilterContent>
                  )}
                </Flex>
                <Flex
                  id="price"
                  py="15px"
                  borderBottomWidth={1}
                  borderColor={borderColor[colorMode]}
                  flexDirection="column"
                >
                  <FilterMenu filter={filter} filterName="price" onOpenFilter={() => onOpenFilter('price')} />
                  {getOpenFilter('price') && open && (
                    <FilterContent id="content">
                      <PriceForm
                        initial={{ currency: query.currency || 'usd', min: query.min || '20', max: query.max || '' }}
                        onSubmit={onSubmitPrice}
                      />
                    </FilterContent>
                  )}
                </Flex>
                <Flex
                  id="collections"
                  py="15px"
                  borderBottomWidth={1}
                  borderColor={borderColor[colorMode]}
                  flexDirection="column"
                >
                  <FilterMenu
                    filter={filter}
                    filterName="collections"
                    onOpenFilter={() => onOpenFilter('collections')}
                  />
                  {getOpenFilter('collections') && open && (
                    <FilterContent id="content">
                      <Input
                        fontSize="13px"
                        placeholder="Search collection"
                        mb="10px"
                        _placeholder={{ color: '#7f7f7f', fontSize: '14px' }}
                        _invalid={{ borderColor: '#e53e3e' }}
                        _focus={{ borderColor: '#000' }}
                        _disabled={{ color: 'gray.500' }}
                        onChange={(e) => debouncedSave(e.target.value)}
                        h="35px"
                      />
                      <Text fontSize="11px" fontWeight="600" mb="5px">
                        Latest Collections
                      </Text>
                      <Box>
                        {isLoading || loading ? (
                          <Loading />
                        ) : (
                          collections.map((i) => {
                            const find = queryCollections.find((el) => Number(el) === Number(i.id));
                            return (
                              <Flex key={i.id} py="5px">
                                <Checkbox
                                  defaultChecked={find ? true : false}
                                  mr="10px"
                                  onChange={() => onAddParam('collections', i.id)}
                                >
                                  <Flex>
                                    <Image
                                      w="22px"
                                      h="22px"
                                      objectFit="cover"
                                      src={i.most_recent_non_fungible_token?.file?.thumbnail_http_url}
                                      size="xs"
                                      mr="10px"
                                      alt=""
                                      borderRadius="50%"
                                    />
                                    <Text fontWeight="600" fontSize="14px">
                                      {i.name}
                                    </Text>
                                  </Flex>
                                </Checkbox>
                              </Flex>
                            );
                          })
                        )}
                      </Box>
                    </FilterContent>
                  )}
                </Flex>
                <Flex
                  id="tags"
                  py="15px"
                  borderBottomWidth={1}
                  borderColor={borderColor[colorMode]}
                  flexDirection="column"
                >
                  <FilterMenu filter={filter} filterName="tags" onOpenFilter={() => onOpenFilter('tags')} />
                  {getOpenFilter('tags') && open && (
                    <FilterContent id="content">
                      {loading ? (
                        <Loading />
                      ) : (
                        tags.map((i) => {
                          const find = queryTags.find((el) => Number(el) === Number(i.id));
                          return (
                            <Checkbox
                              key={i.id}
                              defaultChecked={find ? true : false}
                              onChange={(e) => onAddParam('tags', i.id)}
                              fontWeight="bold"
                              my="7px"
                            >
                              {i.name}
                            </Checkbox>
                          );
                        })
                      )}
                    </FilterContent>
                  )}
                </Flex>
              </Flex>
            </Scrollbars>
          </Sticky>
        </Box>
      ) : (
        <Flex
          id="filters"
          pt={{ base: '25px', md: '25px' }}
          pl="10px"
          pr="15px"
          mr="25px"
          borderRightWidth={1}
          borderColor={borderColor[colorMode]}
          onClick={() => setOpenFilter(true)}
          cursor="pointer"
          minH="65vh"
        >
          <FilterIcon color={colorMode === 'dark' ? '#fff' : '#141414'} />
        </Flex>
      )}
    </Fragment>
  );
};

export default Filters;
