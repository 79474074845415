/* eslint-disable react/no-children-prop */
import React from 'react';
import { Flex, Text, Input, InputGroup, InputLeftElement, InputRightElement, useColorMode } from '@chakra-ui/react';
import { bgColorB } from 'config/data';

const FixedPrice = ({ value, setValue, nftData, for_sale }) => {
  // const inputValue = number(value);
  // const priceUsd = number(nftData?.price_usd) || 0;
  // const estimated_profit = (inputValue - priceUsd) * 0.9;
  const { colorMode } = useColorMode();
  return (
    <Flex id="price" flexDirection="column" mt="45px">
      <Text fontSize="12px" fontWeight="bold" textAlign="center" mb="3px">
        Enter Amount
      </Text>
      <Flex bg={bgColorB[colorMode]} borderRadius={6} p="20px" justifyContent="center" mb="10px" pos="relative">
        {/* <Flex pos="absolute" right={2} top={1}>
          <Menu isLazy>
            <MenuButton alignItems="center" fontWeight="bold">
              {currency} <Icon as={ChevronDownIcon} />
            </MenuButton>
            <MenuList minWidth="120px" defaultValue={currency}>
              <MenuItem defaultValue="USD" onClick={() => setCurrency('USD')}>
                USD
              </MenuItem>
              <MenuItem defaultValue="STX" onClick={() => setCurrency('STX')}>
                STX
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex> */}
        <InputGroup w="240px">
          <InputLeftElement
            pointerEvents="none"
            fontSize="72px"
            children={for_sale === null ? '' : '$'}
            fontWeight="bold"
            mt="15px"
          />
          <Input
            // defaultValue={number(nftData?.price_usd) + number(nftData?.price_usd) * 0.05}
            type="number"
            variant="unstyled"
            placeholder="0"
            height="72px"
            fontSize="72px"
            fontWeight="bold"
            ml="5px"
            onChange={(e) => setValue(e.target.value)}
          />
          {for_sale === null && (
            <InputRightElement pointerEvents="none" fontSize="32px" children="STX" fontWeight="bold" mt="15px" />
          )}
        </InputGroup>
      </Flex>
    </Flex>
  );
};
export default FixedPrice;
