import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import { Box, Button } from '@chakra-ui/react';
import FixedPrice from './fixed';

const Step1 = ({ onSubmit, loading, nftData }) => {
  const { for_sale } = nftData;
  const [value, setValue] = React.useState('');
  const [currency, setCurrency] = React.useState('USD');

  const debouncedValue = useMemo(
    () =>
      debounce((value) => {
        setValue(value);
      }, 500),
    []
  );

  return (
    <Box mt="15px">
      <FixedPrice
        for_sale={for_sale}
        nftData={nftData}
        value={value}
        setValue={debouncedValue}
        currency={currency}
        setCurrency={setCurrency}
      />
      <Button
        mt="40px"
        isLoading={loading}
        variant="black"
        w="100%"
        isDisabled={value ? false : true}
        onClick={() => onSubmit(value, currency)}
      >
        Make offer
      </Button>
    </Box>
  );
};
export default Step1;
