import React, { Fragment } from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const ModalLoading = () => {
  return (
    <Fragment>
      <Box bg="#000" pos="absolute" w="100%" h="100%" opacity="0.3" zIndex="2" top={0} left={0} />
      <Spinner
        id="loader"
        thickness="5px"
        speed="0.65s"
        color="white"
        size="xl"
        top="45%"
        left="50%"
        pos="fixed"
        zIndex="3"
      />
    </Fragment>
  );
};

export default ModalLoading;
