import React from 'react';
import * as yup from 'yup';
import { FiInfo } from 'react-icons/fi';
import { Flex, Text, Button, Icon, Image as ImageChakra } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { Input, Select, SelectCountry } from 'components/InputForm';

// const validationSchema = yup.object().shape({
//   username: yup.string().required('Required'),
// });

const currencies = [
  {
    label: 'United States Dollar',
    value: 'usd',
  },
];

const PriceForm = ({ onSubmit, initial }) => {
  return (
    <Formik
      initialValues={initial}
      //   validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ handleSubmit, submitCount, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              component={Select}
              isClearable={false}
              options={currencies}
              name="currency"
              submitCount={submitCount}
              height="35px"
              hasBottom="10px"
            />
            <Flex>
              <Field
                label="Min"
                name="min"
                placeholder="100"
                component={Input}
                submitCount={submitCount}
                height="35px"
              />
              <Flex px="15px" mt="30px">
                <Text>To</Text>
              </Flex>
              <Field
                label="Max"
                name="max"
                placeholder="100"
                component={Input}
                submitCount={submitCount}
                height="35px"
              />
            </Flex>

            <Button w="100%" fontSize="13px" variant="black" h="35px" type="submit">
              Apply
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default PriceForm;
